.drawer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -2;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	background-color: white;
	transform: translateX(-100%);
	opacity: 0;
	overflow-y: auto;
}

.drawerOpen {
	z-index: 9999;
	transform: translateX(0);
	opacity: 1;
	transition: all 0.3s ease-in-out;
}

.header,
.body {
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.header {
	border-bottom: 1px solid #ebebeb;
}

.body {
	gap: 30px;
}

.close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 22px;
	height: 22px;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: transparent;
	border: 0;

	&::before,
	&::after {
		position: relative;
		content: '';
		height: 22px;
		width: 2px;
		background-color: #dd0528;
	}

	&::before {
		left: 1px;
		transform: rotate(45deg);
	}

	&::after {
		left: -1px;
		transform: rotate(-45deg);
	}
}

.profile {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	padding: 12px;
	color: #000;
	font-size: 14px;
	font-weight: 600;
}

.avatar {
	flex-shrink: 0;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	object-fit: cover;
	overflow: hidden;
}

.userName {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap;
	color: #dd0528;
}

.list {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.link {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	color: #555353;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
	padding: 12px;
	background-color: transparent;
	border: 0;
}

.icon {
	display: inline-flex;
	object-fit: contain;
	filter: saturate(0);
	width: 24px;
	height: 24px;
}

.dropdown {
	display: none;
	padding-left: 12px;
	margin: 0;
	list-style-type: none;
}

.dropdownOpen {
	display: block;
}

.angleDown {
	transform: rotateZ(0);
}

.angleDownOpen {
	transform: rotateZ(180deg);
}
