.container {
	max-width: 440px;
	padding: 0 !important;
}

.closeButton {
	position: absolute;
	width: 22px;
	height: 22px;
	top: 12px;
	right: 12px;
	cursor: pointer;
}

.content {
	height: auto;
	width: 100%;
	padding: 12px 24px;
	text-align: center;
}

.header {
	width: 100%;
	margin-bottom: 10px;
}

.title {
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 900;
	padding-top: 20px;
	margin-bottom: 6px;
}

.subTitle {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 6px;
}

.text {
	color: #414141;
	font-size: 15px;
}

.containerInput {
	height: auto;
}

.divInput {
	margin-bottom: 10px;
	outline: 1px solid #eeeeee;
	height: 54px;
	border-radius: 14px;
	border: none;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.input {
	margin-bottom: 4px !important;
}

.inputHelper {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 12px;
}

.passwordLost {
	text-align: right;
	font-size: 14px;
	color: #2d9eff !important;
	margin-top: 0;
}

@media (max-width: 600px) {
	.passwordLost {
		height: 48px;
		display: inline-flex;
		align-items: center;
	}
}

.register {
	font-size: 14px;
	color: #2d9eff;
	margin-top: 0;
}

.aviso {
	color: #8e8e8e;
	font-size: 15px;
	text-decoration-color: #8e8e8e;
	margin-bottom: 6px;
}

.signupContainer {
	margin-top: 12px;
	padding: 12px 0;
	border-top: 1px solid #ebebeb;
}

.signin {
	width: 100%;
	font-weight: 600;
}

.btnRegister {
	background: #ffffff;
	outline: 1px solid #dc052b;
	color: #dc052b;
}

.text {
	font-size: 14px;
	margin-bottom: 12px;
}

.message {
	display: flex;
	align-items: center;
	padding: 6px 16px;
	background-color: rgb(255, 244, 229);
	border-radius: 4px;
	margin-bottom: 24px;

	svg {
		margin-right: 12px;
	}

	p {
		font-size: 14px;
		color: rgb(102, 60, 0);
		margin: 0;
	}
}

.alertIcon {
	fill: #ff9801;
	width: 20px;
	height: 20px;
}

.signupButton {
	color: #dc052b !important;
	border-color: #dc052b !important;
	width: 100%;
}

// // md
// @media (max-width: 900px) {
// 	.container {
// 		height: auto;
// 		width: 530px;
// 		padding: 20;
// 	}

// 	.div {
// 		width: 90%;
// 		margin: 5px auto;
// 	}

// 	.divText {
// 		height: 54%;
// 		margin-bottom: 0;
// 	}

// 	.title {
// 		font-size: 20px;
// 		padding-top: 0;
// 	}

// 	.subTitle {
// 		font-size: 10px;
// 	}

// 	.text {
// 		font-size: 13px;
// 		margin: 8px 0;
// 	}

// 	.containerInput {
// 		width: 100%;
// 		gap: 10;
// 	}

// 	.divInput {
// 		margin-top: 5px;
// 		width: 48%;
// 		height: 41px;
// 		border-radius: 9px;
// 	}

// 	.passwordLost {
// 		font-size: 12px;
// 		text-align: right;
// 		margin-top: 14px;
// 	}

// 	.register {
// 		font-size: 12px;
// 		text-align: center;
// 		margin-top: 14px;
// 	}

// 	.aviso {
// 		font-size: 10px;
// 	}

// 	.divButton {
// 		gap: 10px;
// 	}

// 	.button {
// 		height: 41px;
// 		font-size: 16px;
// 		width: 50%;
// 		border-radius: 9px;
// 	}
// }

// // sm
// @media (max-width: 600px) {
// 	.container {
// 		width: 100%;
// 		max-width: 390px;
// 		height: auto;
// 		padding: 0;
// 		padding-bottom: 10;
// 	}

// 	.div {
// 		width: 90%;
// 		margin: 10px auto;
// 	}

// 	.divText {
// 		height: 48%;
// 		margin-bottom: 0;
// 	}

// 	.title {
// 		font-size: 14px;
// 		padding-top: 10px;
// 	}

// 	.text {
// 		font-size: 11px;
// 	}

// 	.containerInput {
// 		gap: 0;
// 	}

// 	.divInput {
// 		width: 100%;
// 		height: 41px;
// 	}

// 	.input {
// 		& .muiinputlabel-root {
// 			font-size: 0.9rem;
// 		}
// 	}

// 	.passwordLost {
// 		font-size: 11px;
// 		text-align: right;
// 		margin-top: 0;
// 	}

// 	.register {
// 		font-size: 11px;
// 		text-align: center;
// 		margin-top: 0;
// 	}

// 	.divButton {
// 		margin-top: 15px;
// 		height: auto;
// 	}

// 	.button {
// 		width: 100%;
// 		font-size: 16px;
// 		margin: 0;
// 		padding: 5px;
// 	}
// }
