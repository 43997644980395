.pills {
	display: flex;
	flex-wrap: wrap;
	padding: 12px 0;
	gap: 12px;
}

.pill {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
	padding: 4px 12px;
	background-color: #f4f4f4;
	border-radius: 30px;
	font-size: 14px;
	color: #696969;
	cursor: pointer;
	white-space: nowrap;
	border: 0;

	img {
		opacity: 0.35;
	}

	&:hover {
		background-color: #e0e0e0;
	}
}

@media (max-width: 768px) {
	.pills {
		flex-wrap: nowrap;
		overflow-x: auto;
		padding: 12px;
	}
	.pill {
		font-size: 12px;
	}
}
