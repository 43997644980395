body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-link {
  color: #61dafb;
}

.poll-modal .MuiBackdrop-root {
  display: none !important;
}

@font-face {
  font-family: "AvenirLTStd-Black";
  src: local("AvenirLTStd-Black"), url("./assets/fonts/AvenirLTStd-Black.otf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "AvenirLTStd-Book";
  src: local("AvenirLTStd-Book"), url("./assets/fonts/AvenirLTStd-Book.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "AvenirLTStd-Roman";
  src: local("AvenirLTStd-Roman"), url("./assets/fonts/AvenirLTStd-Roman.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("./assets/fonts/AvenirLTStd-Roman.otf") format("truetype");
  font-weight: 400;
}