.fieldGroup {
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
}

.fieldContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	align-items: center;
	width: 100%;
	padding: 0;
	position: relative;
}

.fieldDate {
	// background-image: url(/assets/icons/landing/form/calendar-icon.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - 16px) center;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	&::-webkit-calendar-picker-indicator {
		background: transparent;
		cursor: pointer;
	}
}

.select {
	select {
		// background-image: url(/assets/icons/landing/form/angle-down-icon.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 16px) center;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		color: white;
	}
}

.label {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 6px;
	font-size: 16px;
	font-weight: 500;
	color: black;
	background-color: #ffffff;
	// border: 1px solid #dee0e4;
	border-radius: 8px;
	padding: 14px 18px;
	cursor: pointer;

	&::before {
		content: '';
		display: flex;
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		margin-right: 10px;
		border-radius: 50%;
		background-color: white;
		border: 1px solid black;
	}
}

.field {
	display: none;

	&:checked {
		& + .label {
			// background-color: #ffebeb;
			border-color: #000;

			&::before {
				background-color: #000;
				border-color: #000;
				box-shadow: inset 0 0 0 2px white;
			}
		}
	}
}

.tooltip {
	position: relative;
	z-index: 2;
	display: flex;
	width: 20px;
	height: 20px;
	margin-left: 6px;

	span {
		display: none;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 224px;
		height: auto;
		padding: 10px 16px;
		font-size: 12px;
		color: var(--complementary-color);
		border-radius: 4px;
		background-color: white;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&:hover {
		span {
			display: block;
		}
	}
}

.error {
	color: red;
	border-color: red;
}

.errorMessage {
	display: flex;
	padding: 3px 0;
	font-size: 12px;
	color: red;
}

// With icon
.withIcon {
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 500;

	&::before {
		content: initial;
	}
}

.icon {
	display: block;
	width: 116px;
	height: 50px;
	margin-bottom: 2rem;
}

.field {
	&:checked {
		& + .label {
			img {
				filter: brightness(100);
			}
		}
	}
}
