.background {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	padding: 24px;
	overflow-y: auto;
}

.content {
	position: relative;
	width: 100%;
	height: auto;
	padding: 24px;
	background-color: white;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 9;
	background-color: transparent !important;
	color: black !important;
	font-size: 14px !important;
}

@media (max-height: 520px) {
	.background {
		justify-content: flex-start;
	}
}
