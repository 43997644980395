.closeButton {
	position: absolute;
	width: 22px;
	height: 22px;
	top: 12px;
	right: 12px;
	cursor: pointer;
}

.title {
	display: inline-block;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	width: 100%;
}
