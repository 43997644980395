.shepherd-button {
  background: #E61737;
  /*border-top: solid 3px #E61737;*/
  border-radius: 0;
  color: #ffffff;
  display: flex;
  flex-grow: 1;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.shepherd-cancel-icon {
  font-family: 'Poppins', sans-serif;
}

.shepherd-element[data-popper-placement^='top'] {
  margin: -30px 0;
}
.shepherd-element[data-popper-placement^='bottom'] {
  margin: 30px 0;
}

.shepherd-element,
.shepherd-header,
.shepherd-footer {
  border-radius: 1;
}

.shepherd-element .shepherd-arrow {
  border-width: 0;
  height: auto;
  width: auto;
}

.shepherd-arrow::before {
  display: none;
}

.shepherd-element .shepherd-arrow:after {
  content: url('https://shepherdjs.dev/landing/assets/img/arrow.svg');
  display: inline-block;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-top .shepherd-arrow {
  bottom: -35px;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
  transform: rotate(270deg);
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow {
  top: -35px;
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow:after {
  transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-left .shepherd-arrow {
  right: -35px;
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
  transform: rotate(180deg);
}

.shepherd-element[data-popper-placement^='right'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-right .shepherd-arrow {
  left: -35px;
}

.shepherd-footer {
  padding: 1rem;
  gap: 1rem;
}

.shepherd-button {
  align-items: center;
  text-transform: capitalize;
  border-radius: 8px;
}

.shepherd-button-secondary {
  font-weight: 600;
  color: #E61737 !important;
  border: 0;
  background: transparent !important;
}

.shepherd-button-secondary:hover,
.shepherd-button-secondary:active  {
  background: rgba(221, 5, 40, 0.04) !important;
}

.shepherd-button:not(.shepherd-button-secondary):hover,
.shepherd-button:not(.shepherd-button-secondary):active {
  border: 0;
  background: #ca102c !important;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  margin-top: -7px;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: white;
  font-family: 'Poppins', sans-serif;
}

.shepherd-has-title .shepherd-content .shepherd-header .shepherd-title {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.shepherd-text {
  font-size: 1rem;
  padding: 0 2rem 1rem 1rem;
}

.shepherd-text a, .shepherd-text a:visited,
.shepherd-text a:active {
  border-bottom: 1px dotted;
  border-bottom-color: rgba(0, 0, 0, 0.75);
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.shepherd-text a:hover, .shepherd-text a:visited:hover,
.shepherd-text a:active:hover {
  border-bottom-style: solid;
}