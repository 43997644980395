.searcher {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	// padding: 9px 20px;
}

.field {
	z-index: 1;
	margin: 0;

	> div {
		padding-top: 0;
	}

	input {
		border-radius: 8px 0 0 8px;
		border-color: black;
		border-radius: 40px;
	}
}

.button {
	position: absolute;
	top: 50%;
	right: 1px;
	transform: translateY(-50%);
	z-index: 2;
	width: 60px !important;
	height: 40px !important;
	background: transparent !important;
	border-radius: 40px !important;
	padding: 0 !important;

	&:focus {
		// box-shadow: initial !important;
	}
}

.wiggle {
	animation: wiggle 0.5s ease-in-out;
}

.searcherIcon {
	width: 32px;
	height: 32px;
}

@keyframes wiggle {
	0% {
		transform: translateX(1%);
	}
	25% {
		transform: translateX(-1%);
	}
	50% {
		transform: translateX(1.5%);
	}
	75% {
		transform: translateX(-5%);
	}
	100% {
		transform: translateX(0%);
	}
}
