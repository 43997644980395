svg {
	float: left;
	margin-right: 5px;
}
.navHeader {
	display: flex;
}

.buttonSearch {
	background-color: #dd0528 !important;
	color: white !important;
	text-transform: capitalize !important;
}
.buttonSearch:hover {
	background-color: #f5082f !important;
}
.imgSocioInfonavit {
	max-width: 190px;
	padding-left: 10px;
	border-left: solid 2px #d1d1d1;
}
.userName {
	color: #dd0528;
}

.header {
	border-bottom: solid 1px #555353;
}

.dropdown-menu {
	height: 70px;
	overflow-y: scroll;
}
.dropdown-menu:before {
	right: 15px;
	top: -16px;
	border: 8px solid #0000;
	border-bottom: 8px solid;
}

.dropdown-menu:after {
	right: 16px;
	top: -14px;
	border: 7px solid #0000;
	border-bottom: 7px solid;
}

.dropdown-menu:before,
.dropdown-menu:after {
	content: '';
	left: auto;
	display: inline-block;
	position: absolute;
}

// Dropdown
.dropdown {
	position: absolute !important;
	flex-direction: column;
	box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
	border: 1px solid #dee0e4;
	border-radius: 8px;

	&.show {
		display: flex;
	}
}

.option {
	display: flex;
	flex-direction: row;
	gap: 8px;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	color: black;
	padding: 6px 20px;
	white-space: nowrap;
	align-items: center;

	&:hover {
		color: #dd0528;
		background-color: rgba(0, 0, 0, 0.03);

		img {
			filter: saturate(1);
		}
	}
}

.optionIcon {
	display: inline-flex;
	width: 24px;
	height: 24px;
	object-fit: contain;
	filter: saturate(0) brightness(0);
}

// Menu
.navbar {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 11;
	padding: 0;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
}

.navbarRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
}

.topMenu {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 60px;
	padding: 14px 0;
}

.topMenuLeft,
.topMenuRight {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 60px;
}

.topMenuLeft {
	flex: 1;
}

.topMenuRight {
	display: flex;
}

.logo {
	display: flex;
	width: auto;
	height: 32px;
}

.search {
	width: 100%;
	max-width: 500px;
}

.userInfoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.userInfo {
	display: flex;
	align-items: end;
	flex-direction: column;
}

.userInfoWelcome {
	color: #000;
	font-size: 12px;
	font-weight: 500;
}

.userInfoToggle {
	color: #000;
	font-size: 12px;
	font-weight: 600;
}

.userInfoDropdown {
	box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
	border: 1px solid #dee0e4;
	border-radius: 8px;
}

.avatar {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	object-position: center center;
}

// Bottom menu
.bottomMenu {
	display: flex;
	flex-direction: row;
}

.menu {
	display: flex;
	flex-direction: row;
	width: 100%;
	color: #555353;
	font-size: 14px;
	font-weight: 600;
}

.menuItem,
.menuItemActive {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 42px;
	padding: 0 8px;
	color: #555353;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
}

.menuItemActive {
	color: #dd0528;
}

.itemRight {
	margin-left: auto;
	margin-right: 0;
}

.bottomMenuRight {
}

// Dropdown login
.dropdownLogin {
	list-style-type: none;

	a {
		padding: 0;

		&::after {
			content: initial;
		}
	}
}

.dropdownMenuLogin {
	min-width: 260px;
	padding: 24px;
}

.signupInvite {
	display: flex;
	font-size: 14px;
	line-height: 1.2;
	text-align: center !important;
	color: #696969;
	margin-bottom: 12px;
}

.signupButton {
	padding-left: 12px !important;
	padding-right: 12px !important;
	border-color: #dd052b !important;
	color: #dd052b !important;
	width: 100%;
	text-decoration: none;
}

.angleIcon {
	display: inline-flex;
	flex-shrink: 0;
	margin-left: 12px;
}
