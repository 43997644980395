.div{
    height: 74px;
    @media (min-width: 576px) { 
        height: 80px;
    }
}
.containerData{
    /* Frame 1 */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  0 16px 100px 16px;
    /*gap: 10px;*/
    margin: auto;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    min-height: calc(80vh - 300px);
    border-radius: 0;
}
.title {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #090909;
    text-align: center;
    /*margin: 22px 42px 0 42px;*/
}
.divStores{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 16px;
    gap: 10px;
}