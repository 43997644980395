.cardsContainer {
	display: grid;
	grid-gap: 24px;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 100px;
}

.col {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.search {
	input {
		border-radius: 40px;
		background-image: url('/assets/icons/searcher-icon.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 12px) center;
	}
}

.title {
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 32px;
}

@media (max-width: 992px) {
	.cardsContainer {
		grid-template-columns: 1fr;
	}
}
