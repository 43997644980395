.fieldGroup {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	margin-bottom: 15px;
}

.fieldContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0;
	position: relative;
}

.label {
	z-index: 2;
	font-size: 16px;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
	color: black;
	margin-bottom: 2px;

	&.disabled {
		opacity: 0;
		color: rgba(0, 0, 0, 0.5);
	}
}

.labelMaterial {
	position: absolute;
	top: 50%;
	left: 12px;
	z-index: 2;
	font-size: 16px;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
	color: black;

	&.disabled {
		opacity: 0;
		color: rgba(0, 0, 0, 0.5);
	}
}

.field {
	position: relative;
	overflow: hidden;
	display: flex;
	width: 100%;
	height: 42px;
	padding: 0 40px 0 12px;
	margin: 0;
	font-size: 16px;
	background-color: transparent;
	border: 1px solid black;
	border-radius: 5px;
	color: black;

	&:disabled {
		border-color: rgba(209, 215, 222, 0.5);
		color: rgba(0, 0, 0, 0.5);
		pointer-events: none;
	}

	&:focus,
	&:active {
		outline: none;
		box-shadow: 0 0 0 4px #f6f6f6;

		& + label {
			top: 12px;
			left: 8px;
			pointer-events: auto;
			font-size: 12px;
			background-color: white;
			padding: 0 4px;
			border-radius: 3px;

			&.disabled {
				color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	&[type='file'] {
		appearance: none;
		cursor: pointer;
	}

	&[type='file']::file-selector-button {
		display: none;
	}
}

.dateField {
	/* background-image: url(/assets/icons/landing/form/calendar-icon.svg); */
	background-repeat: no-repeat;
	background-position: calc(100% - 16px) center;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	&::-webkit-calendar-picker-indicator {
		background: transparent;
		cursor: pointer;
	}
}

.fileField {
	padding-right: 40px;
	/* background-image: url(/assets/icons/landing/form/file-icon.svg); */
	background-repeat: no-repeat;
	background-position: calc(100% - 16px) center;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	&::-webkit-calendar-picker-indicator {
		background: transparent;
		cursor: pointer;
	}
}

.select {
	select {
		/* background-image: url(/assets/images/icons/angle-down.svg); */
		background-repeat: no-repeat;
		background-position: calc(100% - 16px) center;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		color: transparent;
	}
}

.tooltip {
	position: relative;
	z-index: 2;
	display: flex;
	width: 20px;
	height: 20px;
	margin-left: 6px;
}

.tooltip {
	span {
		display: none;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 224px;
		height: auto;
		padding: 10px 16px;
		font-size: 12px;
		color: var(--complementary-color);
		border-radius: 4px;
		background-color: white;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	}
}

.tooltip {
	span {
		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.tooltip {
	&:hover span {
		display: block;
	}
}

.helper {
	font-size: 12px;
	color: rgba(255, 255, 255, 0.7);
	padding: 2px 0;
}

.error {
	color: #dd052b;
	border-color: #dd052b;
}

.errorMessage {
	display: flex;
	padding: 3px 0;
	font-size: 12px;
	color: #dd052b;
}

.filled {
	select {
		color: black !important;
	}
}

.required {
	color: #dd052b;
	font-size: 12px;
	font-weight: 500;
}

@media (max-width: 768px) {
	.label {
		font-size: 12px;
	}
}
