.container {
	margin-bottom: 100px;
}

.mainContainer {
	display: flex;
	justify-content: center;
}
.rightContainer {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.benevitHeader {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.benevitTitle {
	color: #000;
	font-size: 32px;
	font-weight: 700;
	line-height: 140%;
	margin: 0;
}

.infoBenevit {
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
}

.discount {
	border-radius: 4px;
	background: #eafcde;
	color: #318200;
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
	padding: 4px 10px;
}

.category {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	color: black;
	text-align: right;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.categoryIcon {
	display: inline-flex;
	width: 24px;
	height: 24px;
	object-fit: contain;
	object-position: center center;
}

.instructions {
	list-style: none;
	width: 100%;
	overflow: 'hidden';
	text-overflow: 'ellipsis';
	font-size: 16px;

	hr {
		padding: 0;
		margin: 16px 0;
		border-color: transparent !important;
	}

	a {
		word-break: break-all;
	}

	p {
		margin: 0;
	}
}

.characteristics {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.characteristic {
	display: inline-flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	color: #696969;
	font-size: 12px;
	font-weight: 500;

	svg {
		width: 24px;
		height: 24px;
		object-fit: contain;
		object-position: center center;
		margin: 0;
	}
}

.validate {
	color: #b2440d;
	text-align: right;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
}

.actions {
	display: flex;
	flex-direction: row;
	gap: 16px;
}
.btnLike {
	display: inline-flex;
	border-color: black !important;
	display: flex;
	gap: 5px;
}
.btnLikeText {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.percent {
	color: #46a00f;
	font-size: 16px;
	font-weight: 700;
}

.like {
	color: #4f4f5e;
	text-align: left;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px; /* 83.333% */
}
.btnWeb {
	border-radius: 4px;
	background: #dd0528;
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
	display: inline-flex;
	flex-direction: row;
	gap: 12px;
	cursor: pointer;
	padding: 0px 20px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	text-decoration: none;
}

.codeButton {
	position: relative;
	display: flex;
	padding: 6px 10px 6px 20px;
	align-items: center;
	gap: 16px;
	color: #000 !important;
	text-align: left;
	font-size: 16px;
	font-weight: 700 !important;
	line-height: 140%; /* 22.4px */
	cursor: pointer;
}

.copied {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 2px 6px;
	font-size: 12px;
	font-weight: 600;
	white-space: nowrap;
	color: white;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 4px;
}

.ubicacion {
	display: flex;
	justify-content: center;
	align-items: start;
	gap: 10px;
	flex-direction: column;
}
.ubicacionText {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
}
.btnUbicacion {
	border-radius: 4px;
	border: 1px solid #000;
	color: #000;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
	cursor: pointer;
	padding: 10px 20px;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 10px;
}
.btnUbicacion svg {
	float: right;
}
.social2 {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
}

.coverImage {
	width: 100%;
	height: 200px;
	object-fit: scale-down;
	object-position: center center;
	border-radius: 8px;
}

.fillImage {
	object-fit: fill;
	object-position: top right;
}

.divider {
	display: flex;
	width: 100%;
	height: 1px;
	background-color: #ebebeb;
}

@media (max-width: 768px) {
	.coverImage {
		height: 120px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.benevitTitle {
		font-size: 20px;
	}

	.infoBenevit {
		flex-wrap: wrap;
	}

	.actions {
		flex-direction: column;
		gap: 16px;
	}

	.leftContainer {
		margin-bottom: 24px;
	}
}
