.option {
	border-color: black !important;
	color: black !important;
	white-space: nowrap;

	&:focus {
		box-shadow: initial !important;
	}

	&:active,
	&.active {
		border-color: black !important;
		color: white !important;
		background-color: black !important;
	}
}

.optionActive {
	border-color: black !important;
	color: white !important;
	background-color: black !important;
	white-space: nowrap;
}
