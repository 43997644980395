.button {
	height: 50px;
	min-width: 70%;
}

@media (max-width: 768px) {
	.button {
		width: 100%;
		font-size: 12px;
		white-space: nowrap;
	}

	.information {
		margin-bottom: 24px;
	}
}
