.header {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: white;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.top {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 14px 20px;
}

.logo {
	width: auto;
	height: 32px;
}

.burger {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 3px;
	padding: 10px;
	background: transparent;
	border: 0;

	span {
		width: 16px;
		height: 2px;
		background-color: #dd0528;
		border-radius: 2px;
	}
}

.menu {
	display: flex;
	flex-direction: column;
}

.bottom {
	padding: 9px 20px;
}
