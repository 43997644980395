.card {
	position: relative;
	grid-column: 1 / span 1;
	grid-auto-rows: min-content;
	flex-direction: column;
	background-color: white;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
	padding: 24px 70px 24px 24px;
	border: 1px solid #dee0e4;
	border-radius: 12px;
	font-size: 14px;
	// width: calc(50% - 10px);

	h3 {
		font-size: 18px;
	}

	img {
		display: block;
		border: 1px solid #dee0e4;
		margin: 12px 0;
		max-width: 100%;
	}
}

.toggle {
	position: absolute;
	top: 24px;
	right: 24px;
	display: block;
	width: 32px;
	height: 32px;
	border: 0;
	background-color: #f7f7ff;
	border-radius: 6px;
	padding: 0;
}

.toggleActive {
	background-color: #e62334;
}

.iconPlus {
	position: relative;
	display: block;
	width: 32px;
	height: 32px;

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&::before {
		width: 16px;
		height: 2px;
		background-color: #000;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::after {
		width: 2px;
		height: 16px;
		background-color: #000;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.iconMinus {
	position: relative;
	display: block;
	width: 32px;
	height: 32px;

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&::before {
		width: 16px;
		height: 2px;
		background-color: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.answer {
	display: block;
	overflow: hidden;
	max-height: 0;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	font-size: 14px;
}

.show {
	opacity: 1;
	max-height: 9999px;
	margin-top: 24px;
}
