.button {
	--bg-color: transparent;
	--color: #dd0528;
	border: 1px solid var(--color);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 12px 24px;
	height: 42px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	color: var(--color);
	border-radius: 4px;
	cursor: pointer;
	background-color: var(--bg-color);

	&:hover {
		filter: brightness(0.9);
	}

	&:active {
		filter: brightness(0.8);
	}

	&:focus {
		box-shadow: 0 0 0 2px #dd0528;
	}

	&:disabled {
		filter: brightness(0.5);
		cursor: not-allowed;
	}
}
