.banner,
.banner.slick-slider .slick-list,
.banner .slick-slide,
.banner .slick-slide > div {
  height: auto;
}

.banner {
  position: relative;
  overflow: hidden;
  overflow: hidden;
  border-radius: 8px;
}

.banner .slick-track {
  display: flex;
  flex-direction: row;
}

.banner .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  float: none;
}

.banner .slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.banner .slick-dots {
  position: absolute;
  z-index: 2;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  width: auto;
  list-style-type: none;
  margin: 0;
}

.banner .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

.banner .slick-dots li button {
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  overflow: hidden;
  text-indent: -999px;
}

.banner .slick-dots li button::before {
  display: none;
}

.banner .slick-dots li.slick-active button {
  background-color: #DD0528;
}