.breadcrumbs {
	display: flex;
	flex-direction: row;
	gap: 12px;
	padding: 30px 0;
	max-width: 100%;
	overflow-x: auto;
}

.crumb {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}

.crumbLink {
	display: flex;
	color: #6f6f6f;
	font-size: 12px;
	font-weight: 400;
	text-decoration: none;
	gap: 12px;
	white-space: nowrap;
}

.activeCrumbLink {
	display: flex;
	color: black;
	font-size: 12px;
	font-weight: 400;
	text-decoration: none;
	gap: 12px;
	white-space: nowrap;
}
