.button {
	display: inline-flex;
	gap: 5px;
	align-items: center;
	border-color: black !important;
	line-height: 1;
}

.active {
	background-color: #e8fcdd !important;
	border-color: #318200 !important;

	span {
		color: #318200 !important;
	}

	svg {
		animation: l2 0.45s linear;
	}
}

// shake animation
@keyframes l2 {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(16deg);
	}
	50% {
		transform: rotate(-16deg);
	}
	75% {
		transform: rotate(5deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.loader {
	width: 25px;
	padding: 4px;
	aspect-ratio: 1;
	border-radius: 50%;
	margin-right: 5px;
	background: #000;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	mask: var(--_m);
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}

@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.svg {
	width: 25px;
	height: 25px;
	margin: 0;
	float: initial;
	margin-right: 5px;
}

.label {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.percent {
	color: black;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3;
}

.like {
	color: #4f4f5e;
	text-align: left;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px; /* 83.333% */
}
